<template>
	<div class="live-device-center">
		<div class="el-content">
			<div class="ldc-device">
				<div v-if="deviceList.length == 0 " style="color: #999;">
					暂无设备信息
				</div>
				<template v-for="(item,index) in deviceList">
					<div class="ldc-device-item"  v-if="item.is_online" :key="index">
						<div class="ldc-device-set"  @click="toDeviceDetail(item)"><i class="ri-settings-5-fill ri-lg"></i></div>
						<div class="ldc-device-item-top">
							<div class="ldc-device-name" @click="toDeviceDetail(item)">
								<span>{{item.dev_alias}}</span>
								<div style="display: inline-block;">
									<div class="ldc-device-signal">
										<span :style="{background:item.giz_info && item.giz_info.signal>=1?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=2?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=3?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=4?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=5?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=6?'':'#d8d8d8'}"></span>
										<span :style="{background:item.giz_info && item.giz_info.signal>=7?'':'#d8d8d8'}"></span>
									</div>
								</div>
							</div>
							<div class="ldc-device-did" @click="toDeviceDetail(item)">did：{{item.did}}</div>
							<div class="ldc-device-did">mac ：{{ item.mac }}</div>
							<!-- 开关信息 -->
							<div class="ldc-device-switch" v-if="item.giz_info && item.giz_info.swInfo">
								<div class="ldc-device-switch-item" v-for="(val,ind) in item.giz_info.swInfo" :key="ind">
									<template v-if="ind <=4">
										<a-switch @change="swOpen(item.did,val.key,val.value)" v-model:checked="val.value"></a-switch>
										<div class="switch-name">{{val.name}}</div>
									</template>
								</div>
							</div>
						</div>
						<!-- 设备数据信息 -->
						<div class="ldc-device-item-info"  @click="toDeviceDetail(item)">
							<template  v-if="item.giz_info && item.giz_info.AInfo">
								<div class="device-info-li">
									<template  v-for="(val,ind,key) in item.giz_info.AInfo">
										<div class="dil-item" :key="key" v-if="val.params">
											<div>{{val.params.name}}:</div>
											<div>{{val.params.appValueCom}}</div>
										</div>
									</template>
								</div>
							
								
								<!-- 七合一气象站 -->
								<span v-if="item.giz_info.DInfo">
									<span  v-for="(val,ind,key) in item.giz_info.DInfo" :key="ind">
										<div class="device-info-title">{{ val.name }}</div>
										<div class="device-info-li" :key="key">
											<template v-for="(v,i) in val.params">
												<div class="dil-item" v-if="val.params" :key="i">
													<div v-if="v.senname"><span >{{v.senname}}</span>:</div>
													<div v-if="v.name"><span>{{v.name}}:</span></div>
													<template v-if="v.name=='风向'">
														<div><span >{{ $gizRequest.getWindDirection( v.appValue ) }}</span></div>
													</template>
													<template v-else>
														<div v-if="v.appValueCom"><span >{{v.appValueCom}}</span></div>
													</template>
												</div>
											</template>
										</div>
									</span>
									
								</span>
							</template>
						</div>
					</div>
				</template>
			
				
			</div>
		</div>
	</div>
</template>

<script>
import liveModel from '@/api/live'
	var giz = null
	export default{
		data(){
			return{
				value:'',
				show:{
					updateSwitch:false,
					host_name:false,
				},
				updateSwitchForm:{
					open1:'',
					open2:'',
					open3:'',
					open4:'',
				},
				updateDeviceNameForm:{
					name:''
				},
				
			
				config:{
					_app_id:"",
					_token:"",
					_uid:""
				},
				deviceList:{},		//所有设备
				isLoading:false,
			}
		},
		created() {
			let t = this
			if( t.$gizRequest.giz ){
				giz = t.$gizRequest.giz
				
				let _d = giz.did.getOnlineList()
				_d.forEach(item=>{
					t.deviceList[item.did] =item 
					giz.cmd("c2s_read",{did:item.did})
				})
				t.getDeviceList()
			}else{
				t.$gizRequest.init(t).then(()=>{
					giz = t.$gizRequest.giz
					
					//无登录状态重新获取设备进行登录
					liveModel.open_getThingHostList(null,true,res=>{
						res.forEach(item=>{
							t.deviceList[item.did] = item
						})
						t.getDeviceList()
					})
				})
			}
		},
		methods:{
			//获取物联网设备列表
			getDeviceList(){
				let t = this
				// t.isLoading = t.openLoading()
				let timer = setTimeout(function(){
					clearTimeout(timer)
					// t.closeLoading(t.isLoading)
					// 构建访问机智云WebSocket
					giz.handel({
					    open(evt) {
					        console.log("连接已经打开")
					    },
					    send(data) {
					        // console.log("发送指令")
					        // console.log(data)
					    },
					    message(r) {
					        // console.log("响应消息")
					        // console.log(r)
					    },
					    close(evt) {
					        console.log("连接已经关闭");
					    },
					    // 登录事件
					    login(r) {
					        console.log("登录成功")
					        // 登录成功后，获取后端接口返回的在线设备列表并设置
							giz.did.addMany(t.deviceList)
							let dids = Object.keys(t.deviceList)
							dids.forEach( item=>giz.cmd("c2s_read",{did:item.did}) )
							// t.closeLoading(t.isLoading)
					    },
					    // 参数上报事件
					    attrs(_did_) {
							let swInfo = giz.did.getParamSwitch(_did_)
							if( swInfo && swInfo.length > 0 ){
								swInfo.forEach((item,index)=>{
									if( !item.value ){
										swInfo[index].value = false
										// t.$set(swInfo[index],'value',false)
									}
								})
								
								//开关模式
								swInfo[0].type = t.getSwMode(_did_,1)
								swInfo[1].type = t.getSwMode(_did_,2)
								swInfo[2].type = t.getSwMode(_did_,3)
								swInfo[3].type = t.getSwMode(_did_,4)
								swInfo[4].type = t.getSwMode(_did_,5)
								
								//开关值
								swInfo[0].value =  giz.switch.getValue(_did_, 1)
								swInfo[1].value =  giz.switch.getValue(_did_, 2)
								swInfo[2].value =  giz.switch.getValue(_did_, 3)
								swInfo[3].value =  giz.switch.getValue(_did_, 4)
								swInfo[4].value =  giz.switch.getValue(_did_, 5)
								swInfo[5].value =  giz.switch.getValue(_did_, 6)
								swInfo[6].value =  giz.switch.getValue(_did_, 7)
								swInfo[7].value =  giz.switch.getValue(_did_, 8)
								swInfo[8].value =  giz.switch.getValue(_did_, 9)
								swInfo[9].value =  giz.switch.getValue(_did_, 10)
							}
							
							let AInfo = {
								a1Info : giz.sensor.getAObject(_did_, 1),
								a2Info : giz.sensor.getAObject(_did_, 2),
								a3Info : giz.sensor.getAObject(_did_, 3),
								a4Info : giz.sensor.getAObject(_did_, 4),
								a5Info : giz.sensor.getAObject(_did_, 5),
							}
							let DInfo = {
								d1Info : giz.sensor.getDObject(_did_, 1),
								d2Info : giz.sensor.getDObject(_did_, 2),
								d3Info : giz.sensor.getDObject(_did_, 3),
							}
							
							let giz_info = {
								signal:giz.did.getSignal(_did_),
								swInfo : swInfo,	//开关信息
								// 传感器值计算
								AInfo:AInfo,
								DInfo:DInfo,
							}
							if( t.deviceList[_did_] && t.deviceList[_did_].giz_info ){
								t.deviceList[_did_].giz_info = giz_info
							}else{
								t.deviceList[_did_].giz_info = giz_info
								// t.$set(t.deviceList[_did_],"giz_info",giz_info)
							}
					    },
					})
				},1000)
			},
			
			toDeviceDetail(data){
				
				localStorage.setItem("__DEVICE__",JSON.stringify(data))
				this.$router.push({path:"/live/device_detail",query:{did:data.did} })
			},
			
			// 获取开关模式
			getSwMode(_did,index) {
			    let r = giz.switch.getMode(_did, index);
			    if(r[0] === null) {
			        return "未知";
			    }
			    return r[0] + ":" + r[1];
			},
			
			/**
			 * 开关打开关闭操作
			 * @param {Object} did		设备号
			 * @param {Object} index	开关索引
			 * @param {Object} value	开关开启or关闭
			 */
			swOpen(did,index,value){
				if( value ){  //打开开关
					giz.switch.setOpen(did, index)
				}else{  //关闭开关
					 giz.switch.setClose(did, index)
				}
			},
		}
	}
</script>

<style lang="scss">
	.ldc-device{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		&-item{
			width: 380px;
			border: 1px solid #38B44A;
			cursor: pointer;
			margin-right: 8px;
			margin-bottom: 12px;
			background: #396AF2;
			position: relative;
			
			.ldc-device-set{
				width: 35px;
				height: 35px;
				border-radius: 50%;
				background:#38B44A;
				position: absolute;
				margin-left: 345px;
				border-radius: 0 0 0 50px;
				text-align: center;
				line-height: 35px;
				
				i{
					color: #FFFFFF;
					position: relative;
					left: 5px;
					top: -2px;
				}
			}
			
			&-top{
				padding: 12px;
				min-height: 120px;
				background: #fff;
				.ldc-device-name{
					font-weight: bold;
					margin-top: 12px;
				}
				.ldc-device-signal{
					width: 27px;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					position: relative;
					top: 2px;
					span{
						display: inline-block;
						width: 3px;
						background: #396AF2;
					}
					span:first-child { height: 4px; }
					span:nth-child(2){ height: 6px; }
					span:nth-child(3){ height: 8px; }
					span:nth-child(4){ height: 10px; }
					span:nth-child(5){ height: 12px; }
					span:nth-child(6){ height: 14px; }
					span:nth-child(7){ height: 16px; }
				}
				.ldc-device-did{
					font-size: 13px;
					color: #999999;
					margin-top: 4px;
				}
				
				.ldc-device-switch{
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-top: 12px;
					
					&-item{
						width: 20%;
					}
					
					.switch-name{
						font-size: 12px;
						margin-top: 4px;
						font-weight: bold;
					}
					
				}
			}
			
			&-info{
				width: 94%;
				padding: 12px;
				background: #396AF2;
				color: #FFFFFF;
				font-size: 13px;
				min-height: 160px;
				.device-info-title{
					font-weight: bold;
					margin-bottom: 12px;
					color: #eac71e;
				}
				.device-info-li{
					display: flex;
					align-items: center;
					// justify-content: space-between;
					flex-wrap: wrap;
				}
				.dil-item{
					// width: 48%;
					display: flex;
					min-height: 28px;
					// justify-content: space-between;
					
					div:first-child{
						// width: 45%;
						color: rgba($color: #ffffff, $alpha: .8);
					}
					div:last-child{
						// width: 55%;
						text-align: right;
						font-weight: bold;
						margin: 0 12px;
					}
				}
				
				.device-info-btm{
					min-height: 24px;
					.dib-title{
						color: rgba($color: #ffffff, $alpha: .8);
					}
					.dib-val{
						font-weight: bold;
					}
				}
			}
		}
	}
</style>
